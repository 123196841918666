import React, { useState } from "react";
import { graphql } from "gatsby";
import {
  SectionIntro,
  SectionParallaxImages,
  SectionSideTitle,
  SectionSideAccordion,
  SectionRadar,
  SectionAdminInfo,
  SectionTestimonials,
} from "./components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "./../shared/components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SectionContact from "../shared/components/section-contact";
import useScrollPosition from "../shared/hooks/useScrollPosition";

const Home = (props) => {
  const scrollPosition = useScrollPosition();
  const { pageContext } = props
  const { t } = useTranslation();

  const metas = {
    title: t("seo.home.title"),
    description: t("seo.home.description")
  }

  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
    >
      <SectionIntro></SectionIntro>
      <SectionParallaxImages scrollPosition={ scrollPosition}></SectionParallaxImages>
      <SectionSideTitle></SectionSideTitle>
      <SectionSideAccordion></SectionSideAccordion>
      <SectionRadar></SectionRadar>
      <StaticImage
        src={"../../assets/images/balonmano.jpg"}
        objectFit="cover"
        alt={t("alts.contact1")}
      />
      <SectionContact scrollPosition={ scrollPosition} type="full" />
      <SectionAdminInfo />
      <SectionTestimonials></SectionTestimonials>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
