import React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import IconList from "../../../shared/components/icon-list";
import { Radar } from "../../../shared/components/radar";
import {
  SvgLiveMatches,
  SvgMessages,
  SvgNews,
  SvgQr,
} from "../../../shared/svg/icons";
import { useInView } from 'react-intersection-observer';

import "./index.scss";
const SectionRadar = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  const listElements = [
    {
      icon: <SvgLiveMatches></SvgLiveMatches>,
      text: t("home.sectionRadar.l1"),
    },
    {
      icon: <SvgQr></SvgQr>,
      text: t("home.sectionRadar.l2"),
    },
    {
      icon: <SvgNews></SvgNews>,
      text: t("home.sectionRadar.l3"),
    },
    {
      icon: <SvgMessages></SvgMessages>,
      text: t("home.sectionRadar.l4"),
    },
  ];

  return (
    <section ref={ref} className={`section-radar${inView ? ' in-view' : ''}`}>
      <div className="wrapper-fluid">
        <div className="main-info grid">
          <p className="featured">{t("home.sectionRadar.paragraph")}</p>

          <div className="radar-wrapper">
            <Radar></Radar>
          </div>

          <IconList
            elements={listElements}
            pretitle={t("home.sectionRadar.pretitle")}
            title={t("home.sectionRadar.title")}
            link={language === 'es' ? '/app/' : '/aplikazioa/'}
            cta={t("home.sectionRadar.cta")}
          ></IconList>
        </div>
      </div>
    </section>
  );
};

export default SectionRadar;
