import React from "react";
import { useTranslation } from "react-i18next";
import AccordionSection from "../../../shared/components/accordion-section";

const SectionSideAccordion = () => {

  const { t } = useTranslation();

  const sideAccordionItems = t("home.sectionSideAccordion", { returnObjects: true })

  return (
    <section className={`section-side-accordion`}>
      <AccordionSection
        type="home"
        items={sideAccordionItems}
      />
    </section>
  );
};

export default SectionSideAccordion;
