import React, { useState } from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import {
  SvgProfile,
  SvgFee,
  SvgCommunications,
  SvgTarget,
} from "../../../shared/svg/icons";
import IconList from "../../../shared/components/icon-list";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from 'react-intersection-observer';
import { InView } from "react-intersection-observer";
import { usePageContext } from "../../../shared/components/layout/pageContextProvider";
import "./index.scss";


const SectionAdminInfo = () => {
  const { t } = useTranslation()

  const { language } = useI18next()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  const [adminInfoInView, setAdminInfoInView] = useState(false);
  const { handleSectionAdminInfoInViewHandler } = usePageContext()

  const listElements = [
    {
      icon: <SvgProfile></SvgProfile>,
      text: t("home.sectionAdminInfo.l1"),
    },
    {
      icon: <SvgFee></SvgFee>,
      text: t("home.sectionAdminInfo.l2"),
    },
    {
      icon: <SvgCommunications></SvgCommunications>,
      text: t("home.sectionAdminInfo.l3"),
    },
    {
      icon: <SvgTarget></SvgTarget>,
      text: t("home.sectionAdminInfo.l4"),
    },
  ];
  const toggleAdminInfoInView = (inView) => {
    setAdminInfoInView(inView);
    handleSectionAdminInfoInViewHandler(inView);
  };
  return (
    <InView as="div" onChange={(inView) => toggleAdminInfoInView(inView)}>
      <section ref={ref} className={`section-admin-info ${adminInfoInView ? 'in-view' : 'not-in-view'}`}>
        <div className="wrapper-fluid">
          <div className="main-info grid">
            <div className="admin-img">
              <figure>
                <StaticImage
                  src="../../../../assets/images/ipad.png"
                  alt={t("home.alts.contact2")}
                />
              </figure>
              <StaticImage
                src="../../../../assets/images/ipad3.png"
                alt=""
              />
              <StaticImage
                src="../../../../assets/images/ipad1.jpg"
                alt=""
              />
              <StaticImage
                src="../../../../assets/images/ipad2.png"
                alt=""
              />
            </div>

            <IconList
              elements={listElements}
              pretitle={t("home.sectionAdminInfo.pretitle")}
              title={t("home.sectionAdminInfo.title")}
              link={language === 'es' ? '/app/' : '/aplikazioa/'}
              cta={t("home.sectionAdminInfo.cta")}
            ></IconList>
          </div>
        </div>
      </section>
    </InView>
  );
};

export default SectionAdminInfo;
