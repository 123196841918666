import React, { useRef } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PreTitle from "../../../shared/components/pre-title";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid, Autoplay, EffectCreative } from 'swiper';
import DynamicImage from "../../../shared/components/dynamic-image";
import SvgBlurredQuote from "../../../shared/svg/svg-blurred-quote";
import { useInView } from "react-intersection-observer";
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import './index.scss'

const SectionTestimonials = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const testimonials = t("home.sectionTestimonials.testimonials", { returnObjects: true })
  const teams = t("home.sectionTestimonials.teams", { returnObjects: true })

  const testimonialSliderRef = useRef(null)

  const testimonialsPagination = {
    el: '.slider-testimonials .swiper-pagination',
    clickable: false,
    renderBullet: function (index, className) {
      const slide = testimonialSliderRef.current.querySelectorAll('.swiper-wrapper > .swiper-slide:not(.swiper-slide-duplicate)')[index]
      const slideName = slide.querySelector('.testimonial-name').textContent
      const slideRole = slide.querySelector('.testimonial-role').textContent
      return `<div class="${className}">
                <div class="swiper-pagination-bullet-index">              
                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.8443 48.931C11.726 48.931 1.09174 38.301 1.09174 25.1886C1.09174 12.0761 11.726 1.44609 24.8443 1.44609C37.9627 1.44609 48.5969 12.0761 48.5969 25.1886C48.5969 38.301 37.9627 48.931 24.8443 48.931Z" stroke="#ECECEC" stroke-width="0.9"/>
                    <path class="circle" d="M24.841 48.9473C11.7165 48.9473 1.07735 38.311 1.07735 25.1909C1.07735 12.0707 11.7165 1.43437 24.841 1.43437C37.9655 1.43437 48.6047 12.0707 48.6047 25.1909C48.6047 38.311 37.9655 48.9473 24.841 48.9473Z" fill="transparent" stroke="#72A325" stroke-width="1.4"/>
                  </svg>               
                  <span class="index">${index < 10 ? `0${index + 1}` : index + 1}</span>
                </div>
                <div class="swiper-pagination-bullet-content">
                  <span>${slideName}</span>
                  <span>${slideRole}</span>
                </div>
              </div>
              `;
    },
  };

  return (
    <section ref={ref} className={`section-testimonials${inView ? ' in-view' : ''}`}>
      <div className="background-quote">
        <SvgBlurredQuote />
      </div>
      <div className="wrapper-fluid">
        <div className="main-info grid">
          <PreTitle text={t("home.sectionTestimonials.pretitle")}></PreTitle>
          <h2 className="title" dangerouslySetInnerHTML={{
            __html: t("home.sectionTestimonials.title")
          }}></h2>
          <Swiper
            ref={testimonialSliderRef}
            pagination={testimonialsPagination}
            modules={[Pagination, Autoplay, EffectCreative]}
            className="slider-testimonials"
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          // effect={"creative"}
          // creativeEffect={{
          //   prev: {
          //     opacity: 0,
          //   },
          //   next: {
          //     opacity: 1,
          //   },
          // }}
          >
            {
              testimonials.map((testimonial, index) => {
                return (
                  <SwiperSlide key={`testimonials-slide-${index}`}>
                    <div className="testimonial-header-wrapper">
                      <DynamicImage imageKey={testimonial.icon} />
                      <div className="testimonial-header">
                        <span className="testimonial-name">{testimonial.name}</span>
                        <span className="testimonial-role">{testimonial.role}</span>
                      </div>
                    </div>
                    <p className="testimonial-cite">{testimonial.cite}</p>
                  </SwiperSlide>
                )
              })
            }
            <div className="swiper-pagination"></div>
          </Swiper>

          <Swiper
            grid={{
              rows: 3,
              fill:'row'
            }}
            slidesPerView={3}
            slidesPerGroupAuto={true}
            slidesPerGroup={3}
            spaceBetween={20}
            pagination={{ clickable: true, el: '.slider-teams .swiper-pagination' }}
            modules={[Grid, Pagination, Autoplay]}
            className="slider-teams"
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          // breakpoints={{
          //   1440: {
          //     spaceBetween: 30
          //   },
          //   2560: {
          //     spaceBetween: 30
          //   }
          // }
          // }
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          // loop={true}
          >
            {
              teams.map((team, index) => {
                return (
                  <SwiperSlide key={`teams-slide-${index}`}>
                    <div className="slide-wrapper">
                      <div className="slide-icon">
                        <DynamicImage imageKey={team.icon} />
                      </div>
                      <div className="slide-name">{team.name}</div>
                    </div>
                  </SwiperSlide>
                )
              })
            }
            <div className="swiper-pagination"></div>
          </Swiper>
        </div>
      </div >
    </section >
  );
};

export default SectionTestimonials;
