import React from "react";
import PreTitle from "../pre-title";
import { Link } from "gatsby";
import { sendAnalyticEvent } from "../../hooks/useAnalyticEvent";
import "./index.scss";

const IconList = ({ elements, link = '/', pretitle, title, cta }) => {

  return (
    <div className={`icon-list`}>
      <PreTitle text={pretitle}></PreTitle>
      <h2 className="title">{title}</h2>
      <ul>
        {elements.map((el, key) => {
          return (
            <li key={`${el.text}-${key}`}>
              {el.icon}<span>{el.text}</span>
            </li>
          );
        })}
      </ul>
      <Link to={link} className="btn stroked small" onClick={() => sendAnalyticEvent('customEvent', 'Accion/Más información', title, cta)}>
        {cta}
      </Link>
    </div>
  );
};

export default IconList;
