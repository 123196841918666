import React from "react";
import "./index.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PreTitle from "../../../shared/components/pre-title";
import { useInView } from 'react-intersection-observer';

const SectionSideTitle = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px"
  });

  return (
    <section ref={ref} className={`section-side-title${inView ? ' in-view' : ''}`} >
      <div className="wrapper-fluid">
        <div className="main-info grid">
          <div className="title">
            <PreTitle
              enter={false}
              text={t("home.sectionSideTitle.pretitle")}
            ></PreTitle>
            <h2 className="title">{t("home.sectionSideTitle.title")}</h2>
          </div>
          <p className="description">{t("home.sectionSideTitle.paragraph")}</p>
        </div>
      </div>
    </section>
  );
};

export default SectionSideTitle;
