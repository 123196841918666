import React, { useEffect, useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useInView, InView } from 'react-intersection-observer';
import "./index.scss";


const SectionParallaxImages = ({ scrollPosition }) => {

  const [initialYPosition, setInitialYPosition] = useState(0);
  const [parallaxPosition, setParallaxPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [mounted, setMounted] = useState(false)

  const sectionRef = useRef(null);
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.4,
  });

  const getParallaxPosition = () => {
    return false;
    // const initialPositionCorrection = - 400;
    // const relativeScrollPosition = scrollPosition - initialYPosition;
    // const parallaxPos = initialPositionCorrection - ((relativeScrollPosition) > (0 - initialYPosition) ? relativeScrollPosition : 0);

    // if(inView && !isMobile){
    //   setParallaxPosition(parallaxPos)
    // }
  }

  useEffect(() => {
    if (!mounted) {
      setIsMobile(window.innerWidth < 1024);
      setMounted(true)
    }
    setInitialYPosition(sectionRef.current.getBoundingClientRect().top + document.documentElement.scrollTop + 100)
  }, [])

  useEffect(() => {
    getParallaxPosition();
  }, [scrollPosition])

  return (

    <section ref={ref} className={`section-parallax-image ${inView ? ' in-view' : ''}`}>
      <div ref={sectionRef} className="wrapper-fluid">
        <div className="grid">

          <figure style={
            { transform: "translateY(" + parallaxPosition * 0.65 + "px)" }
          }>
            <StaticImage
              src="../../../../assets/images/equipo-futbol.jpg"
              alt={t("home.alts.parallax1")}
            />
          </figure>
          <figure style={
            { transform: "translateY(" + parallaxPosition * 0.25 + "px)" }
          }>
            <StaticImage
              src="../../../../assets/images/gb-equipo-baloncesto.jpg"
              alt={t("home.alts.parallax2")}
            />
          </figure>
          <figure style={
            { transform: "translateY(" + parallaxPosition * 1 + "px)" }
          }>
            <StaticImage
              src="../../../../assets/images/ciclista-caja-rural.jpg"
              alt={t("home.alts.parallax3")}
            />
          </figure>
        </div>
      </div>
    </section>

  );
};

export default SectionParallaxImages;
