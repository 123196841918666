import React from "react";
import Lottie from "lottie-react";
import LottieRadar from "../../../shared/assets/lotties/lottie-radar.json";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import "./index.scss";

export const Radar = () => {
  const { i18n, t } = useTranslation();

  const cards = {
    es: [
      <StaticImage
        src={"../../../../assets/images/radar-1-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-2-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-3-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-4-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-5.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
    ],
    eu: [
      <StaticImage
        src={"../../../../assets/images/radar-1-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-2-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-3-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-4-es.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
      <StaticImage
        src={"../../../../assets/images/radar-5.png"}
        objectFit="contain"
        alt=""
        placeholder={'none'}
      />,
    ],
  };
  return (
    <div className="radar">
      <Lottie animationData={LottieRadar} loop={true} />
      <div className="cards">
        {cards[i18n.language].map((image) => {
          return image;
        })}
      </div>
    </div>
  );
};
