import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PreTitle from "../../../shared/components/pre-title";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from 'react-intersection-observer';
import SectionIntroSecondary from "../section-intro-secondary/section-intro-secondary";
import "./index.scss";

const SectionIntro = () => {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <section ref={ref} className={`section-intro${inView ? ' in-view' : ''}`}>
      <div className="wrapper-fluid">
        <div className="main-info grid">
          <div className={`content`}>
            <PreTitle
              enter={false}
              text={t("home.sectionIntro.preTitle")}
            ></PreTitle>
            <h1>{t("home.sectionIntro.title")}</h1>
            <div className="description p-wrapper">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("home.sectionIntro.paragraph"),
                }}
              ></p>
            </div>
          </div>

          <figure>
            <StaticImage
              src="../../../../assets/images/intro-mobiles.png"
              alt={t("home.alts.homeIntro")}
              placeholder="none"
              quality={100}
              breakpoints={[2000, 2560, 2800, 3200]}
              loading="eager"
            />
          </figure>
        </div>

        <SectionIntroSecondary />

      </div>
    </section >
  );
};

export default SectionIntro;
