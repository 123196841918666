import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ContactForm from "../contact-form";
import MovingText from "../moving-text";
import { StaticImage } from "gatsby-plugin-image";
import "./index.scss";
import BgLines from "../bg-lines";
import { InView } from "react-intersection-observer";
import { usePageContext } from "../layout/pageContextProvider";

const SectionContact = ({ type = "default", scrollPosition }) => {
  const [initialYPosition, setInitialYPosition] = useState(0);
  const [parallaxPosition, setParallaxPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslation();
  const [scrollCount, setScrollCount] = useState(0);
  const [prevScroll, setPrevScroll] = useState(0);
  const [contactInView, setContactInView] = useState(false);
  const sectionRef = useRef(null);

  // onChange: toggleListener

  const { isFormOpen, handleSectionContactInView } = usePageContext();

  const toggleListener = (addListener) => {
    console.log(addListener);
    if (addListener) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
  };

  const handleScroll = (e) => {
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

    if (st > prevScroll) {
      scrollCount(scrollCount + 1);
    } else {
      scrollCount(scrollCount - 1);
    }
    setPrevScroll(st <= 0 ? 0 : st);
  };

  const toggleContactInView = (inView) => {
    setContactInView(inView);
    handleSectionContactInView(inView);
  };

  const getParallaxPosition = () => {
    return false
    // const initialPositionCorrection = -400;
    // const relativeScrollPosition = scrollPosition - initialYPosition;
    // const parallaxPos =
    //   initialPositionCorrection -
    //   (relativeScrollPosition > 0 - initialYPosition
    //     ? relativeScrollPosition
    //     : 0);

    // if (contactInView && !isMobile) {
    //   setParallaxPosition(parallaxPos);
    // }
    
  };

  useEffect(() => {
    if(!mounted){
      setIsMobile(window.innerWidth < 1024);
      setMounted(true)
    }
    const windowHeight = window.innerHeight;
    setInitialYPosition(
      sectionRef.current.getBoundingClientRect().top +
        document.documentElement.scrollTop +
        windowHeight * 1.5
    );
  }, []);

  useEffect(() => {
    getParallaxPosition();
  }, [scrollPosition]);

  return (
    <InView
      as="div"
      threshold="0.25"
      onChange={(inView) => toggleContactInView(inView)}
    >
      <section
        ref={sectionRef}
        className={`section-contact section-contact--${type} ${
          contactInView ? "in-view" : "not-in-view"
        }`}
      >
        <BgLines></BgLines>
        <div className="wrapper-fluid">
          <div className="main-info grid">
            <div className="content">
              <h2>{t("home.sectionContact.title")}</h2>
              <p>{t("home.sectionContact.paragraph")}</p>
            </div>

            {/* {scrollCount} */}
            <div className="contact-form">
              <ContactForm disableForm={isFormOpen}></ContactForm>
            </div>

            {type === "full" && (
              <div className="figures grid">
                <figure
                  style={{
                    transform: "translateY(" + parallaxPosition * 1 + "px)",
                  }}
                >
                  <StaticImage
                    src="../../../../assets/images/voleibol-equipo.jpg"
                    alt={t("home.alts.contact2")}
                  />
                </figure>

                <figure
                  style={{
                    transform: "translateY(" + parallaxPosition * 0.3 + "px)",
                  }}
                >
                  <StaticImage
                    src="../../../../assets/images/eurocup-lointek.jpg"
                    alt={t("home.alts.contact3")}
                  />
                </figure>
                {/* <MovingText
                text={t("home.sectionContact.bgText")}
                translation={scrollCount}
              ></MovingText> */}
              </div>
            )}
          </div>
        </div>
      </section>
    </InView>
  );
};

export default SectionContact;
