import React from "react";

const SvgBlurredQuote = () => {
    return (
        <svg width="171" height="159" viewBox="0 0 171 159" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_770_59577)">
                <path d="M40.5089 152.478C29.9629 152.478 21.7019 148.163 15.7258 139.532C10.1013 130.901 7.28906 118.331 7.28906 101.82C7.28906 79.3053 12.0347 59.7927 21.5261 43.2819C28.9952 30.2891 39.1854 18.9229 52.0968 9.18326C57.9622 4.75876 66.2151 6.88992 69.8845 13.255C73.255 19.1014 71.5488 26.4917 66.3713 30.82C57.6402 38.119 50.7771 45.8378 45.7818 53.9764C39.7926 63.398 36.4887 74.9317 35.8701 88.5775C35.7641 90.9153 38.1687 92.8141 40.5089 92.8141C47.5395 92.8141 53.8671 95.2532 59.4916 100.131C65.4676 105.01 68.4557 112.139 68.4557 121.52C68.4557 131.277 65.8192 138.969 60.5462 144.598C55.2732 149.851 48.5941 152.478 40.5089 152.478ZM133.313 152.478C122.767 152.478 114.506 148.163 108.53 139.532C102.906 130.901 100.094 118.331 100.094 101.82C100.094 79.3053 104.839 59.7927 114.331 43.2819C121.8 30.2891 131.99 18.9229 144.901 9.18325C150.767 4.75875 159.02 6.88992 162.689 13.255C166.059 19.1014 164.353 26.4917 159.176 30.82C150.445 38.119 143.582 45.8378 138.586 53.9764C132.597 63.398 129.293 74.9317 128.675 88.5775C128.569 90.9153 130.973 92.8141 133.313 92.8141C140.344 92.8141 146.672 95.2532 152.296 100.131C158.272 105.01 161.26 112.139 161.26 121.52C161.26 131.277 158.624 138.969 153.351 144.598C148.078 149.851 141.399 152.478 133.313 152.478Z" fill="#ECECEC" />
            </g>
            <defs>
                <filter id="filter0_f_770_59577" x="0.987851" y="0.544126" width="169.745" height="158.235" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="3.15061" result="effect1_foregroundBlur_770_59577" />
                </filter>
            </defs>
        </svg>
    );
};

export default SvgBlurredQuote;
