import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next";
import { useInView } from "react-intersection-observer";
import { sendAnalyticEvent } from "../../../shared/hooks/useAnalyticEvent";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./index.scss";

const SectionIntroSecondary = () => {

    const { t } = useTranslation()
    const { language } = useI18next();
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-150px 0px',
    });

    return (
        <div ref={ref} className={`secondary-info grid${inView ? ' in-view' : ''}`}>
            <div className="content">
                <p
                    className="featured"
                    dangerouslySetInnerHTML={{
                        __html: t("home.sectionIntro.infoParagraph"),
                    }}
                ></p>
                <Link to={language === 'es' ? '/app/' : '/aplikazioa/'} className="btn small stroked" onClick={() => sendAnalyticEvent('customEvent', 'Accion/Más información', t("home.sectionIntro.title"), t("home.sectionIntro.cta"))}>
                    {t("home.sectionIntro.cta")}
                </Link>
            </div>
        </div>
    )
}

export default SectionIntroSecondary